import { post, resolveGet, resolvePost } from '@/request'
import { SUFFIX } from '@/common' // 用户分页列表

// 地面站分页
export const queryDictionaryPage = async (params: any) => {
  return resolvePost({
    suffix: SUFFIX.DCOP,
    url: '/dictionary/page/query',
    params,
  })
}

// 地面站-添加
export const dictionaryInfoAdd = async (params: any) => {
  return resolvePost({
    suffix: SUFFIX.DCOP,
    url: '/dictionary/add',
    params,
  })
}

// 地面站-更新
export const dictionaryInfoUpdate = async (params: any) => {
  return resolvePost({
    suffix: SUFFIX.DCOP,
    url: '/dictionary/update',
    params,
  })
}

// 地面站-删除
export const dictionaryInfoDelete = async (params: any) => {
  return resolvePost({
    suffix: SUFFIX.DCOP,
    url: '/dictionary/delete',
    params,
  })
}

// 地面站-下啦列表
export const queryDictionaryTreeList = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.DCOP,
    url: '/dictionary/treeList/query',
    params,
  })
}

// 地面站-过境预测
export const queryDictionaryPass = async (params: any) => {
  return resolvePost({
    suffix: SUFFIX.DCOP,
    url: '/dictionary/pass/forecast',
    params,
  })
}

// 地面站-过境预测导出
export const exportDictionaryPass = async (params?: any) => {
  return post({
    suffix: SUFFIX.DCOP,
    url: '/dictionary/pass/export',
    params,
    userConfig: {
      responseType: 'arraybuffer',
      timeout: 100000,
    },
  })
}

// 地面站-过境预测-单颗
export const queryDictionaryPassCompare = async (params: any) => {
  return resolvePost({
    suffix: SUFFIX.DCOP,
    url: '/dictionary/pass/compare',
    params,
  })
}

// 地面站-过境预测导出-单颗
export const exportComparePass = async (params?: any) => {
  return post({
    suffix: SUFFIX.DCOP,
    url: '/dictionary/compare/export',
    params,
    userConfig: {
      responseType: 'arraybuffer',
      timeout: 100000,
    },
  })
}

// 分页查询查询设备发货记录
export const queryDdviceDeliveryPage = async (params: any) => {
  return resolvePost({
    suffix: SUFFIX.DCOP,
    url: '/device/deliveryPage/query',
    params,
  })
}

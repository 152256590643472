// 系统管理
import { get, post, resolveGet, resolvePost } from '@/request'

import { SUFFIX } from '@/common'

// 数据中心-子设备变量
export const queryDeviceAttr = async (params: any) => {
  return resolveGet({
    suffix: SUFFIX.DCOP,
    url: '/device/attr/chartPhysicsBox',
    params,
  })
}

// 数据中心-历史趋势图 -聚合
export const queryDeviceChartPhysicsHistory = async (params: any) => {
  return resolvePost({
    suffix: SUFFIX.DCOP,
    url: '/device/attr/chartPhysicsHistory',
    params,
  })
}
// 数据中心-历史趋势图 -实时
export const queryDeviceChartRealTimePhysicsHistory = async (params: any) => {
  return resolvePost({
    suffix: SUFFIX.DCOP,
    url: '/device/attr/chartPhysicsRealTime',
    params,
  })
}

// 数据中心-历史数据
export const queryDeviceListPhysicsHistoryPage = async (params: any) => {
  return resolvePost({
    suffix: SUFFIX.DCOP,
    url: '/device/attr/listPhysicsHistoryPage',
    params,
  })
}

// 数据中心-导出历史数据
export const exportPhysicsHistory = (params: any) => {
  return post({
    suffix: SUFFIX.DCOP,
    url: '/device/attr/physicsHistory/export',
    params,
    userConfig: {
      responseType: 'arraybuffer',
    },
  })
}

//设备列表-数据查看-表格
export const queryAttrSensorPhysicsView = (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.DCOP,
    url: '/device/attr/sensorPhysicsView',
    params,
  })
}

// 获取已下发的指令信息 - 列表
export const queryDeviceInstructPage = async (params: any) => {
  return resolvePost({
    suffix: SUFFIX.DCOP,
    url: '/device/instruct/info/page',
    params,
  })
}

//获取子设备指令
export const queryDeviceInstructSensor = (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.DCOP,
    url: '/device/instruct/sensor/query',
    params,
  })
}

//下发子设备指令
export const executeDeviceInstruct = async (params: any) => {
  return resolvePost({
    suffix: SUFFIX.DCOP,
    url: '/device/instruct/sensor/execute',
    params,
  })
}

//获取已下发的指令信息 - 单条
export const queryDeviceInstructInfo = (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.DCOP,
    url: '/device/instruct/info/get',
    params,
  })
}

// 系统管理
import { resolveGet, resolvePost, get, post } from '@/request'
import { SUFFIX } from '@/common'

// 添加用户
export const queryAlarmMsgPage = async (params: any) => {
  return resolvePost({
    suffix: SUFFIX.DCOP,
    url: '/alarm/msg/page',
    params,
  })
}

//下拉查询-告警策略列表-包含配置的传感器信息
export const queryAlarmOtherInfoTreelist = async (params: any) => {
  return resolveGet({
    suffix: SUFFIX.DCOP,
    url: '/alarm/strategy/otherInfoTreelist',
    params,
  })
}

// 解除告警
export const alarmMsgRelieve = async (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.DCOP,
    url: '/alarm/msg/relieve',
    params,
  })
}

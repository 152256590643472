// 租户管理
import { get, post, resolveGet, resolvePost } from '@/request'

import { SUFFIX } from '@/common'

// 分页查询-租户列表
export const queryTenantPage = (params: any) => {
  return resolvePost({
    suffix: SUFFIX.DCOP,
    url: '/tenant/page/query',
    params,
  })
}

// 添加租户
export const createTenantInfo = (params: any) => {
  return resolvePost({
    suffix: SUFFIX.DCOP,
    url: '/tenant/info/add',
    params,
  })
}

// 修改租户
export const updateTenantInfo = (params: any) => {
  return resolvePost({
    suffix: SUFFIX.DCOP,
    url: '/tenant/info/update',
    params,
  })
}

// 租户详情
export const queryTenantInfo = (params: any) => {
  return resolveGet({
    suffix: SUFFIX.DCOP,
    url: '/tenant/info/get',
    params,
  })
}

// 删除租户
export const deleteTenantInfo = (params: any) => {
  return resolveGet({
    suffix: SUFFIX.DCOP,
    url: '/tenant/info/delete',
    params,
  })
}

// 首页- 租户-位置列表
export const queryTenantLocationList = (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.DCOP,
    url: '/tenant/locationList/query',
    params,
  })
}

// 下拉查询-租户列表
export const queryTenantTreeList = (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.DCOP,
    url: '/tenant/treeList/query',
    params,
  })
}

// 查询微星公司租户
export const queryTenantTopOrg = (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.DCOP,
    url: '/tenant/infoHome/get',
    params,
  })
}

//查询套餐列表信息
export const queryTenantPackageList = async (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.DCOP,
    url: '/tenant/packageList/query',
    params,
  })
}

//开通套餐或续费套餐
export const tenantPackageOrder = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.DCOP,
    url: '/tenant/package/order',
    params,
  })
}

//查询租户下的资源
export const queryTenantResource = async (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.DCOP,
    url: '/tenant/resource/query',
    params,
  })
}

//分页查询订单记录
export const queryTenantOrder = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.DCOP,
    url: '/tenant/pageOrder/query',
    params,
  })
}

//下拉查询-租户列表及子组织信息
export const queryTenantTreeListContainOrg = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.DCOP,
    url: '/tenant/treeListContainOrg/query',
    params,
  })
}

import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import { PLATFORM_TYPE } from '@/common'
import router from '@/router'

export const useGlobalStore = defineStore('global', () => {
  const platformType = ref(PLATFORM_TYPE.OPERATION)

  const setPlatformType = (value: string) => {
    platformType.value = value
  }

  const changePlatformType = () => {
    if (platformType.value === PLATFORM_TYPE.OPERATION) {
      platformType.value = PLATFORM_TYPE.DATA_CENTER
      router.push('/data-application/export')
    } else {
      platformType.value = PLATFORM_TYPE.OPERATION
      router.push('/')
    }
  }

  return { platformType, changePlatformType, setPlatformType }
})

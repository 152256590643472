import axios from 'axios'
import { ElMessage } from 'element-plus'
import { debounce } from 'lodash'
import { CODE_ENUM, SUFFIX } from '@/common'
import router from '@/router'

const toastMessage = debounce(() => {
  ElMessage({
    message: '登录已过期',
    type: 'error',
    grouping: true,
  })
}, 800)

const { VUE_APP_BASE_URL } = process.env

const instance: any = axios.create({
  baseURL: VUE_APP_BASE_URL,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
})

instance.interceptors.request.use(
  (config: any) => {
    // token
    const authorization = localStorage.getItem('WX_Authorization')

    // 部分请求 需要清除 Authorization
    config.headers['Authorization'] = authorization
      ? 'Bearer ' + authorization
      : ''
    if (config.headers?.emptyAuthorization === 'true') {
      delete config.headers['Authorization']
      delete config.headers['emptyAuthorization']
    }
    return config
  },
  (error: any) => {
    console.log(error)

    return Promise.reject(error)
  }
)

// 获取arraybuffer里面的头部信息
const createArraybufferResponse = (response: any) => {
  const headers = response.headers
  const msg = JSON.parse(decodeURIComponent(headers['server-response-msg']))
  const fileNameEncode = headers['content-disposition'].split('filename=')[1]
  const fileName = decodeURIComponent(fileNameEncode)
  const blob = new Blob([response.data], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  })

  return {
    msg,
    fileName,
    blob,
  }
}

instance.interceptors.response.use(
  (response: any) => {
    if (response?.config?.responseType === 'arraybuffer') {
      return createArraybufferResponse(response)
    } else {
      // 统一处理错误
      // if (response.data?.code === 1) {
      //   ElMessage({
      //     message: response.data?.msg || '未知错误',
      //     type: 'error',
      //     grouping: true,
      //   })
      // }
      return response.data
    }
  },
  (error: any) => {
    if (error?.response?.status === CODE_ENUM.AUTHORIZATION_NOT) {
      toastMessage()
      localStorage.removeItem('WX_Authorization')
      localStorage.removeItem('WX_AuthorizationUser')
      router.push('/login')
      return Promise.reject(error)
    }
    // 登陆接口的账号密码错误 返回400 待沟通更改状态码
    if (error?.response?.status === 400) {
      return Promise.resolve(error.response?.data || {})
    }
    ElMessage({
      message: error?.message || '未知错误',
      type: 'error',
      grouping: true,
    })
    return Promise.reject(error)
  }
)

export const get = (data: any) => {
  const { url, suffix, ...p } = data
  const uri = suffix + url
  return instance.get(uri, p)
}

export const post = (data: any) => {
  const { url, suffix, params = {}, userConfig } = data
  const uri = suffix + url
  return instance.post(uri, params, { ...userConfig })
}

export const resolveGet = async (data: any) => {
  return new Promise(async (resolve: any) => {
    const res: any = await get(data)
    const context = data.context

    if (!context && res?.code !== CODE_ENUM.SUCCESS) {
      ElMessage({
        message: res?.msg || '服务异常',
        type: 'error',
        grouping: true,
      })
    }
    resolve(res)
  })
}

export const resolvePost = async (data: any) => {
  return new Promise(async (resolve: any) => {
    const res: any = await post(data)
    const context = data.context
    if (!context && res?.code !== CODE_ENUM.SUCCESS) {
      ElMessage({
        message: res?.msg || '服务异常',
        type: 'error',
        grouping: true,
      })
    }
    resolve(res)
  })
}

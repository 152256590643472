import * as echarts from 'echarts'

export const colorConf = [
  {
    color: 'rgba(129,226,255)',
    opacityColor: 'rgba(129,226,255,0.22)',
  },
  {
    color: 'rgba(100,162,255)',
    opacityColor: 'rgba(100,162,255,0.22)',
  },
  {
    color: 'rgba(255,211,100)',
    opacityColor: 'rgba(255,211,100,0.22)',
  },
  {
    color: 'rgba(64, 221, 174)',
    opacityColor: 'rgba(64, 221, 174,0.22)',
  },
  {
    color: 'rgba(255,172,50)',
    opacityColor: 'rgba(255,172,50,0.22)',
  },
  {
    color: 'rgba(255,55,91)',
    opacityColor: 'rgba(255,55,91,0.22)',
  },
]

export const getLineColor = (index: number) => {
  if (colorConf.length - 1 < index) {
    return {}
  }
  const { color, opacityColor } = colorConf[index]
  return {
    areaStyle: {
      color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
        {
          offset: 0,
          color: opacityColor,
        },
        {
          offset: 1,
          color: 'rgba(255,255,255,0)',
        },
      ]),
    },
    lineStyle: {
      color,
    },
    itemStyle: {
      color,
    },
  }
}

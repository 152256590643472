import md5 from 'md5'
import sha384 from 'crypto-js/sha384'
import { ElMessageBox } from 'element-plus'
import { isEmpty } from 'lodash'
import moment from 'moment'
import { PRODUCT_TYPE, DATA_TYPE } from '@/common'

const reg = /(\d)(?=(?:\d{3})+$)/g
// 将数字中逗号分隔
export const transformNumber = (number: number) => {
  return number.toString().replace(reg, '$1,')
}

// 明文加密，md5->sh384
export const encode = (input: string) => {
  return sha384(md5(input)).toString()
}

// 验证码加密，sh384
export const sha = (input: string) => {
  return sha384(input).toString()
}

// 将枚举转成数组下拉
export const mapToList = (map: any) => {
  return Object.keys(map).map((key: any) => {
    return {
      label: map[key],
      value: key,
    }
  })
}

// 将枚举接口返回数据转换成下拉选项
export const transformEnum = (list: any) => {
  return list.map((item: any) => {
    const { code, name } = item
    return {
      label: name,
      value: code,
    }
  })
}

// 转换日志内容
export const transformLogContent = (attrList: any, formatterMap?: any) => {
  return attrList.map(({ key, value, label }: any) => {
    return {
      label,
      value:
        formatterMap && formatterMap[key] ? formatterMap[key](value) : value,
      key,
    }
  })
}

// 下载文件
export const downLoadFile = ({ blob, fileName }: any) => {
  const objectUrl = URL.createObjectURL(blob) //创建下载的链接
  const a = document.createElement('a')
  a.href = objectUrl
  a.download = fileName //设置文件名
  a.dispatchEvent(
    new MouseEvent('click', { bubbles: true, cancelable: true, view: window })
  )
  window.URL.revokeObjectURL(blob) //释放bolb对象
}

// 删除公共提示框
export const msgbox = ({
  title = '删除提示',
  tip,
  thenFn = () => {},
  catchFn = () => {},
}: any) => {
  ElMessageBox.confirm(tip, title, {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning',
    autofocus: false,
  })
    .then(thenFn)
    .catch(catchFn)
}

// 获取表单时间
export const formatFormTime = (data: any, keyList: any) => {
  if (isEmpty(data)) {
    return
  }
  const [start, end] = data
  const [startKey, endKey] = keyList
  return {
    [startKey]: moment(start).valueOf(),
    [endKey]: moment(end).valueOf(),
  }
}

// 校验子设备信息必填
export const validateSubDevicesRequired = (
  subDeviceList: any,
  productType: any
) => {
  const validateKeys =
    PRODUCT_TYPE.SINGLE_GATEWAY === productType
      ? ['productKey', 'sensorId']
      : ['productKey', 'busType', 'busId', 'sensorId']
  return subDeviceList.some((item: any) => {
    return validateKeys.map((key: string) => Boolean(item[key])).includes(false)
  })
}

// 校验子设备是否重复
export const validateSubDevices = (
  index: number,
  subDeviceList: any,
  productType: any
) => {
  const validateKeys =
    PRODUCT_TYPE.SINGLE_GATEWAY === productType
      ? ['sensorId']
      : ['busType', 'busId', 'sensorId']
  const subDevice = subDeviceList[index]

  const hasEmptyKey = validateKeys
    .map((key: string) => Boolean(subDevice[key]))
    .includes(false)

  if (hasEmptyKey) {
    return false
  }

  const filterList = subDeviceList.filter((item: any, i: number) => i !== index)

  let totalFlag = false
  filterList.map((item: any) => {
    // 对比全部字段
    let flag = true
    validateKeys.map((key: string) => {
      if (subDevice[key] !== item[key]) {
        flag = false
      }
    })

    if (flag) {
      totalFlag = true
    }
  })

  return totalFlag
}

// 物模型去除多余字段
export const removeModelField = (data: any) => {
  const {
    type,
    min,
    max,
    step,
    unit,
    unitName,
    desc,
    maxLen,
    trueValue,
    falseValue,
    properties,
  } = data
  if (type === DATA_TYPE.INT || type === DATA_TYPE.DOUBLE) {
    return {
      type,
      min,
      max,
      step,
      unit,
      unitName,
      desc,
    }
  } else if (type === DATA_TYPE.STRING) {
    return {
      type,
      maxLen,
    }
  } else if (type === DATA_TYPE.BOOL) {
    return {
      type,
      falseValue,
      trueValue,
    }
  } else if (type === DATA_TYPE.STRUCT) {
    return { type, properties }
  } else {
    return { type }
  }
}

export const getDate = (month: number, unit: any) => {
  const current = moment().format('YYYY-MM-DD')
  const preDate = moment(current).subtract(month, unit).format('YYYY-MM-DD')
  return [
    new Date(moment(preDate + ' 00:00:00').valueOf()),
    new Date(moment(current + ' 23:59:59').valueOf()),
  ]
}

// scrollTop animation
export function scrollTop(
  el: any,
  from = 0,
  to: number,
  duration = 500,
  endCallback?: any
) {
  const _window: any = window

  if (!_window.requestAnimationFrame) {
    _window.requestAnimationFrame =
      _window.webkitRequestAnimationFrame ||
      _window.mozRequestAnimationFrame ||
      _window.msRequestAnimationFrame ||
      function (callback: any) {
        return _window.setTimeout(callback, 1000 / 60)
      }
  }
  const difference = Math.abs(from - to)
  const step = Math.ceil((difference / duration) * 50)

  function scroll(start: any, end: any, step: any) {
    if (start === end) {
      endCallback && endCallback()
      return
    }

    let d = start + step > end ? end : start + step
    if (start > end) {
      d = start - step < end ? end : start - step
    }

    if (el === _window) {
      _window.scrollTo(el.scrollX, d)
    } else {
      el.scrollTop = d
    }
    _window.requestAnimationFrame(() => scroll(d, end, step))
  }
  scroll(from, to, step)
}

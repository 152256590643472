import { get, post, resolveGet, resolvePost } from '@/request'

import { SUFFIX } from '@/common'

// 获取产品列表
export const queryProductPage = (params: any) => {
  return resolvePost({
    suffix: SUFFIX.DCOP,
    url: '/product/page/list',
    params,
  })
}

// 添加产品
export const productInfoAdd = (params: any) => {
  return resolvePost({
    suffix: SUFFIX.DCOP,
    url: '/product/info/add',
    params,
  })
}

// 更新产品
export const productInfoUpdate = (params: any) => {
  return resolvePost({
    suffix: SUFFIX.DCOP,
    url: '/product/info/update',
    params,
  })
}

// 删除产品
export const productInfoDelete = (params: any) => {
  return resolvePost({
    suffix: SUFFIX.DCOP,
    url: '/product/info/delete',
    params,
  })
}

// 获取产品类型
export const queryProductType = (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.DCOP,
    url: '/product/type/query',
    params,
  })
}

// 获取物模型列表
export const queryProductThingModelList = (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.DCOP,
    url: '/deviceThing/base/list/query',
    params,
  })
}

// 获取子设备产品信息
export const querySubProduct = (params: any) => {
  return resolveGet({
    suffix: SUFFIX.DCOP,
    url: '/product/subDeviceList/query',
    params,
  })
}

// 获取单条产品
export const queryProductInfo = (params: any) => {
  return resolveGet({
    suffix: SUFFIX.DCOP,
    url: '/product/info/get',
    params,
  })
}

// 获取网关产品信息(除子设备)
export const queryProductGatewayList = (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.DCOP,
    url: '/product/gatewayList/query',
    params,
  })
}

// 获取子设备产品信息
export const queryProductSubDeviceList = (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.DCOP,
    url: '/product/subDeviceList/query',
    params,
  })
}

// 获取总线类型信息
export const queryProductBusType = (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.DCOP,
    url: '/product/busType/query',
    params,
  })
}

// 上传图片
export const productImgUpload = (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.DCOP,
    url: '/product/upload',
    params,
  })
}

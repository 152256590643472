// 系统管理
import { get, post, resolveGet, resolvePost } from '@/request'

import { SUFFIX } from '@/common'

// 用户分页列表
export const queryDevicePage = async (params: any) => {
  return resolvePost({
    suffix: SUFFIX.DCOP,
    url: '/device/page/query',
    params,
  })
}

// 添加设备
export const deviceInfoAdd = async (params: any) => {
  return resolvePost({
    suffix: SUFFIX.DCOP,
    url: '/device/info/add',
    params,
  })
}

// 修改设备
export const deviceInfoUpdate = async (params: any) => {
  return resolvePost({
    suffix: SUFFIX.DCOP,
    url: '/device/info/update',
    params,
  })
}

// 设备详情
export const queryDeviceInfo = async (params: any) => {
  return resolveGet({
    suffix: SUFFIX.DCOP,
    url: '/device/info/get',
    params,
  })
}

// 删除设备
export const deleteDeviceInfo = async (params: any) => {
  return resolvePost({
    suffix: SUFFIX.DCOP,
    url: '/device/info/delete',
    params,
  })
}

// 批量发货
export const deviceBatchDelivery = async (params: any) => {
  return resolvePost({
    suffix: SUFFIX.DCOP,
    url: '/device/delivery/batch',
    params,
  })
}

// 高级查询 设备
export const queryDeviceTreeList = async (params: any) => {
  return resolvePost({
    suffix: SUFFIX.DCOP,
    url: '/device/treeList/query',
    params,
  })
}

// 主动采集
export const deviceMethodQueryProperties = async (params: any) => {
  return resolvePost({
    suffix: SUFFIX.DCOP,
    url: '/device/method/queryProperties',
    params,
  })
}

// 在线检测
export const deviceMethodOnlinecheck = async (params: any) => {
  return resolvePost({
    suffix: SUFFIX.DCOP,
    url: '/device/method/onlinecheck',
    params,
  })
}

// 设备定位-包含地面站
export const queryDeviceTreeListAll = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.DCOP,
    url: '/device/treeListAll/query',
    params,
  })
}

// 设备定位单个条件
export const queryDeviceLocationBycondit = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.DCOP,
    url: '/device/locus/location/queryAll/bycondit',
    params,
  })
}

// 保存属性呈现方式
export const updateDeviceAttrPresentMode = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.DCOP,
    url: '/device/attribute/presentMode/save',
    params,
  })
}
// 查询绑定卡号的单模设备
export const queryDeviceBindCardList = async (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.DCOP,
    url: '/device/config/bind/card/list',
    params,
  })
}

// 保存设备配置
export const saveDeviceBindCardList = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.DCOP,
    url: '/device/config/topics/save',
    params,
  })
}

// 获取设备卡号列表
export const queryDeviceBindCardHistoryList = async (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.DCOP,
    url: '/device/config/topics/list',
    params,
  })
}

// 添加设备模板下载
export const queryDeviceBatchTemplate = async (params?: any) => {
  return post({
    suffix: SUFFIX.DCOP,
    url: '/device/info/add/template',
    params,
    userConfig: {
      responseType: 'arraybuffer',
    },
  })
}

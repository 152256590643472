// 数据中台
import { get, post, resolveGet, resolvePost } from '@/request'

import { SUFFIX } from '@/common'

// 数据列表导出
export const exportDataHandleList = async (params: any) => {
  return post({
    suffix: SUFFIX.DCOP,
    url: '/data/handle/list/export',
    params,
    userConfig: {
      responseType: 'arraybuffer',
    },
  })
}
